import { useCallback } from 'react'
import type { UseRefinementListProps } from 'react-instantsearch-core'
import { useCurrentRefinements } from 'react-instantsearch-core'
import { useRouter } from 'next/router'

import { orderBy } from 'lodash'
import { useDeepCompareMemo } from 'use-deep-compare'

import {
  ALGOLIA_FACETS_MAP,
  HIERARCHICAL_CATEGORIES,
  TYPE_FILTERS_PREFIX,
} from '@/constants/algolia'
import { useCategories } from '@/providers/c/CategoriesProvider'
import type { FilterOptions } from '@/types'
import { useT } from '@/utils/frontend/useT'
import { useUnleash } from '@/utils/frontend/useUnleash'
import { isNumeric } from '@/utils/plain/isNumeric'
import { parseNumberWithUnitByLocale } from '@/utils/plain/parseNumberWithUnitByCountry'
import { splitLocale } from '@/utils/plain/splitLocale'

type Facet = {
  t: string
  order?: number
  displayOnAllProductsPage?: boolean
  includedLocales?: string[]
}

type FacetToShow = {
  attributeTechnicalName: string
  title: string
  order?: number
  hasSelectedOptions?: boolean
}

const displayFacetOnCurrentLocale = (facet: Facet, locale: string) => {
  if (facet.includedLocales != null) {
    return facet.includedLocales?.includes(locale)
  }
  return !!facet.order
}

const displayFacetOnAllProductsPage = (facet: Facet, locale: string) => {
  if (facet.displayOnAllProductsPage) {
    return displayFacetOnCurrentLocale(facet, locale)
  }
  return false
}

export const usePrepareRefinementListFacets = () => {
  const { t } = useT({ keyPrefix: 'catalog.filters' })
  const { locale } = useRouter()
  const { lang } = splitLocale(locale)

  const { items } = useCurrentRefinements()
  const { isAllProducts } = useCategories()

  const { isEnabledTypeFilters } = useUnleash()

  const facetsToDisplay = useDeepCompareMemo(() => {
    const isAllProductsFromAlgoliaState = !items.some(
      (item) => item.attribute === HIERARCHICAL_CATEGORIES[0],
    )
    const isAllProductsCategory = isAllProducts || isAllProductsFromAlgoliaState

    const facets: FacetToShow[] = []

    Object.entries(ALGOLIA_FACETS_MAP.refinementList).forEach(
      ([attributeTechnicalName, facet]) => {
        const item = items.find((i) => i.attribute === attributeTechnicalName)
        const hasSelectedOptions = !!item && item.refinements.length > 0

        const facetToShow = {
          attributeTechnicalName,
          title: t(facet.t),
          order: 'order' in facet ? facet.order : undefined,
          hasSelectedOptions,
        }

        // for all products page we display only global facets
        if (isAllProductsCategory) {
          if (displayFacetOnAllProductsPage(facet, locale)) {
            facets.push(facetToShow)
          }
          return
        }

        // let's check if the facet is locale specific
        if (displayFacetOnCurrentLocale(facet, locale)) {
          facets.push(facetToShow)
          return
        }

        // for type specific filters
        if (attributeTechnicalName.startsWith(TYPE_FILTERS_PREFIX) && isEnabledTypeFilters) {
          facets.push(facetToShow)
        }
      },
    )

    const sortedFacets = orderBy(
      facets,
      ['hasSelectedOptions', 'order', 'title'],
      ['desc', 'asc', 'asc'],
    )

    return sortedFacets
  }, [items, isEnabledTypeFilters])

  const sortBy: NonNullable<UseRefinementListProps['sortBy']> = useCallback(
    (a: FilterOptions, b: FilterOptions): number => {
      // if escapedValue can be transformed to a number, we assume it's a numeric sort
      if (isNumeric(a.escapedValue) && isNumeric(b.escapedValue)) {
        return (
          parseNumberWithUnitByLocale(a.escapedValue, locale) -
          parseNumberWithUnitByLocale(b.escapedValue, locale)
        )
      }

      // try catch because not all the browsers supports locale specific comparation
      try {
        return a.name.localeCompare(b.name, lang, { sensitivity: 'base' })
      } catch (e) {
        return a.name.localeCompare(b.name)
      }
    },
    [lang, locale],
  )

  return { facets: facetsToDisplay, sortBy }
}
