import type { UseRefinementListProps } from 'react-instantsearch-core'
import { useClearRefinements, useRefinementList } from 'react-instantsearch-core'

import { FilterChip } from '@knauf-group/ct-designs/components/core/FilterChip'
import type { QaHook } from '@knauf-group/ct-designs/utils/types'

import { useCategoriesAnalyticsTracking } from '@/utils/frontend/analytics/useCategoriesAnalyticsTracking'
import { useT } from '@/utils/frontend/useT'

type SelectedItems = { value: string; label: string }

export type ApplySelectionHandler = (selectedItems: SelectedItems[], attribute: string) => void

type MultiselectFilterChipProps = UseRefinementListProps & {
  title: string
  hide?: boolean
  onClickApply: ApplySelectionHandler
} & QaHook

export const MultiselectFilterChip = (props: MultiselectFilterChipProps) => {
  const { trackResetFiltersEvent } = useCategoriesAnalyticsTracking()
  const { title, dataCy, hide = false, onClickApply, ...useRefinementListProps } = props
  const { attribute } = useRefinementListProps

  const { t } = useT({ keyPrefix: 'catalog.filters' })

  const { items } = useRefinementList(useRefinementListProps)
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: [attribute],
  })

  // we hide the filter from parent conditions (e.g. product type not selected)
  if (hide) return null
  // we hide the filter if there is 0 options available
  if (items.length === 0) return null

  const selectedValues = items.filter((item) => item.isRefined).map((item) => item.value)
  const options = items.map((item) => ({ label: item.label, value: item.value }))

  const onClickReset = () => {
    clearRefinements()
    trackResetFiltersEvent(t('reset'), [attribute], selectedValues)
  }

  return (
    <FilterChip
      label={title}
      options={options}
      key={attribute}
      dataCy={attribute}
      multiple
      selectedValues={selectedValues}
      actionsProps={{
        ApplyButtonProps: {
          label: t('apply-filters'),
          onClick: (selectedItems) => onClickApply(selectedItems, attribute),
        },
        ResetButtonProps: { label: t('reset'), onClick: onClickReset },
      }}
    />
  )
}
