import { useInstantSearch } from 'react-instantsearch-core'

import { Box } from '@mui/material'

import { MultiselectFilterChip } from '@/components/CategoriesPage/algolia/MultiselectFilterChip'
import { ResetFilterButton } from '@/components/CategoriesPage/algolia/ResetFiltersButton'
import { usePrepareRefinementListFacets } from '@/components/CategoriesPage/usePrepareRefinementListFacets'
import { useRenderKey } from '@/providers/c/RenderKeyProvider'
import { useCategoriesAnalyticsTracking } from '@/utils/frontend/analytics/useCategoriesAnalyticsTracking'

import type { ApplySelectionHandler } from './algolia/MultiselectFilterChip/MultiselectFilterChip'

const DesktopFacets = () => {
  const { facets, sortBy } = usePrepareRefinementListFacets()
  const { forceNewRenderForFilterChips, renderKey } = useRenderKey()
  const { trackFilterEvent } = useCategoriesAnalyticsTracking()

  // TODO should be coming from the index configuration and not being hardcoded
  const maxFacetHits = 50

  const { setIndexUiState } = useInstantSearch()

  const onClickApplyHandler: ApplySelectionHandler = (selectedItems, attribute) => {
    const selectedItemsValues = selectedItems.map((i) => i.value)

    trackFilterEvent(attribute, selectedItemsValues)

    setIndexUiState((state) => ({
      ...state,
      refinementList: {
        ...state.refinementList,
        [attribute]: selectedItemsValues,
      },
    }))
  }

  return (
    <Box
      data-cy="filter-list"
      sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {facets.map((attribute) => {
        const { attributeTechnicalName, title } = attribute

        return (
          <MultiselectFilterChip
            key={`${attributeTechnicalName}${renderKey}`}
            attribute={attributeTechnicalName}
            title={title}
            dataCy={attributeTechnicalName}
            limit={maxFacetHits}
            sortBy={sortBy}
            onClickApply={onClickApplyHandler}
          />
        )
      })}

      <ResetFilterButton
        variant="outlined"
        dataCy="filter-chips-reset-all-filters"
        startIcon={undefined}
        onClick={forceNewRenderForFilterChips}
      />
    </Box>
  )
}

export default DesktopFacets
